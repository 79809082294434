import { Box, Typography } from '@mui/material'

export function HelloPage(): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: 2,
        padding: 2,
        overflow: 'hidden',
      }}>
      <Typography variant="h1" component="h1">
        Bonjour
      </Typography>
      <Typography variant="subtitle1" component="p">
        Bienvenue sur la vue exploitants de l’application <strong>TM36 Mon entreprise</strong>.
      </Typography>
    </Box>
  )
}
