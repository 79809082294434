import { Autocomplete, Box, Button, Checkbox, CircularProgress, createFilterOptions, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AbcChip from './AbcChip'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { AccountDto } from '../api-client'
import { useAccountsApi } from '../lib/api-clients'

export interface AbcAccountOption {
  id: number
  username: string
}

const accountsToOptions = (accounts: AccountDto[]): AbcAccountOption[] => {
  return accounts
    .flatMap(account => (account.abcAccount ? [{ id: account.id, username: account.abcAccount.username }] : []))
    .sort((a, b) => a.username.localeCompare(b.username))
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export interface AbcUsersAutocompleteProps {
  label?: React.ReactNode
  value?: AbcAccountOption[]
  onChange?: (value: AbcAccountOption[]) => void
}

export default function AbcUsersAutocomplete(props: AbcUsersAutocompleteProps) {
  const { label, value, onChange } = props

  const accountsApi = useAccountsApi()

  const [options, setOptions] = useState<AbcAccountOption[]>([])
  const [selectedAbcAccounts, setSelectedAbcAccounts] = useState<AbcAccountOption[]>([])

  const loading = options.length === 0

  useEffect(() => {
    if (value) {
      setSelectedAbcAccounts(value)
    }
  }, [value])

  const setValue = (newValue: AbcAccountOption[]) => {
    onChange?.(newValue)
    setSelectedAbcAccounts(newValue)
  }

  const getOptionKey = (option: AbcAccountOption) => option.id
  const getOptionLabel = (option: AbcAccountOption) => option.username

  const filterOptions = createFilterOptions<AbcAccountOption>()

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const names = event.clipboardData
      .getData('text')
      .split(/[,;\t\r\n]+/)
      .map(str => str.trim())

    const matched: AbcAccountOption[] = []

    for (const name of names) {
      const filtered = filterOptions(options, {
        getOptionLabel,
        inputValue: name,
      })

      if (filtered.length === 1) {
        matched.push(filtered[0])
      }
    }

    event.preventDefault()
    setValue(Array.from(new Set([...selectedAbcAccounts, ...matched]).values()))
  }

  const handleCopy = () => {
    const usernames = selectedAbcAccounts.map(account => account.username)
    const plainTextData = usernames.join(', ')
    const htmlTextData = '<table>' + usernames.map(username => `<tr><td>${username}</td></tr>`).join('\n') + '</table>'

    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([plainTextData], { type: 'text/plain' }),
      'text/html': new Blob([htmlTextData], { type: 'text/html' }),
    })

    navigator.clipboard.write([clipboardItem])
  }

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    accountsApi.getAccounts().then(response => {
      if (active) {
        setOptions(accountsToOptions(response.accounts))
      }
    })

    return () => {
      active = false
    }
  }, [loading, accountsApi])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        loading={loading}
        options={options}
        getOptionKey={getOptionKey}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        value={selectedAbcAccounts}
        onChange={(_, newValue) => setValue(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            onPaste={handlePaste}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderTags={(accounts, getTagProps) =>
          accounts.map((account, index) => {
            const { key, ...ChipProps } = getTagProps({ index })
            return <AbcChip key={key} ChipProps={ChipProps} username={account.username} />
          })
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.username}
          </li>
        )}
      />
      <Button
        variant="text"
        size="small"
        color="success"
        endIcon={<ContentCopyIcon />}
        sx={{ alignSelf: 'end' }}
        disabled={selectedAbcAccounts.length === 0}
        onClick={handleCopy}>
        Copier
      </Button>
    </Box>
  )
}
