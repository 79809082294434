import { Box, Paper, Typography } from '@mui/material'
import { Outlet, useParams } from 'react-router-dom'
import { ConversationTable } from './ConversationTable'
import { ConversationThread } from './ConversationThread'
import { GroupMessage } from './GroupMessage'

export function ConversationHelpFrame(): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: 2,
        padding: 2,
      }}>
      <Typography variant="subtitle1" component="p">
        Choisissez une conversation sur le panneau de gauche pour discuter en direct avec un conducteur. Vous pouvez
        initier une nouvelle conversation ou envoyer un message de groupe en utilisant le bouton «&nsbp;Écrire un
        message&nbsp;».
      </Typography>
    </Box>
  )
}

export function ConversationThreadFrame(): JSX.Element {
  const { conversationId } = useParams()

  return (
    <>
      {conversationId && (
        <Paper sx={{ flex: 1 }}>
          <ConversationThread key={conversationId} conversationId={parseInt(conversationId, 10)} />
        </Paper>
      )}
    </>
  )
}

export function GroupMessageFrame(): JSX.Element {
  return (
    <Paper sx={{ flex: 1 }}>
      <GroupMessage />
    </Paper>
  )
}

export function ConversationsPage(): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: 2,
        padding: 2,
        overflow: 'hidden',
      }}>
      <Paper sx={{ flex: 1 }}>
        <ConversationTable />
      </Paper>
      <Outlet />
    </Box>
  )
}
