import { createContext, useContext } from 'react'

export interface AlertService {
  success(message: string): void
  info(message: string): void
  warn(message: string): void
  error(message: string): void
}

export class LoggingAlertService implements AlertService {
  success(message: string): void {
    console.log(message)
  }
  info(message: string): void {
    console.info(message)
  }
  warn(message: string): void {
    console.warn(message)
  }
  error(message: string): void {
    console.error(message)
  }
}

const AlertServiceContext = createContext<AlertService>(new LoggingAlertService())
AlertServiceContext.displayName = 'AlertServiceContext'

export const AlertServiceProvider = AlertServiceContext.Provider
export const useAlertService = () => useContext(AlertServiceContext)
