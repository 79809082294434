import { Box, Paper } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { UserAccountsTable } from './UserAccountsTable'

export function UserAccountsPage() {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: 2,
        padding: 2,
        overflow: 'hidden',
      }}>
      <Paper sx={{ flex: 1 }}>
        <UserAccountsTable />
      </Paper>
      <Outlet />
    </Box>
  )
}
