import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { AccountDto } from '../../api-client'
import AbcChip from '../../components/AbcChip'
import { useAlertService } from '../../lib/alerts'
import { useAccountsApi } from '../../lib/api-clients'

export function UserAccountsTable() {
  const alerts = useAlertService()
  const accountsApi = useAccountsApi()

  const [accounts, setAccounts] = useState<AccountDto[]>([])

  useEffect(() => {
    async function fetchAccounts() {
      try {
        const response = await accountsApi.getAccounts()
        setAccounts(response.accounts)
      } catch (error) {
        alerts.error('Erreur lors du chargement des comptes utilisateurs. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    fetchAccounts()
  }, [alerts, accountsApi, setAccounts])

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box padding={2}>
        <Typography component="strong" variant="h6">
          Comptes utilisateurs
        </Typography>
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Identifiant ABC</TableCell>
              <TableCell>Adresse email administrateur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map(account => (
              <TableRow
                key={account.id}
                hover={true}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell align="right">{account.id}</TableCell>
                <TableCell component="th" scope="row">
                  {account.displayName}
                </TableCell>
                <TableCell>
                  <AbcChip username={account.abcAccount?.username} />
                </TableCell>
                <TableCell>{account.adminAccount?.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
