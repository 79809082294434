import { Box, Button, Divider, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material'
import QRCode from 'qrcode'
import { useEffect, useState } from 'react'

const createDeeplink = (username: string, password: string): string => {
  if (!username || !password) {
    return ''
  }

  return (
    `fr.abstraho.tm36monentreprise://` +
    `?username=${encodeURIComponent(username)}` +
    `&password=${encodeURIComponent(password)}`
  )
}

export function QrcodePage(): JSX.Element {
  const [username, setUsername] = useState('DUPONT')
  const [password, setPassword] = useState('123a9')
  const [imageUrl, setImageUrl] = useState('')

  const deeplink = createDeeplink(username, password)

  useEffect(() => {
    if (!deeplink) {
      setImageUrl('')
      return
    }

    QRCode.toDataURL(deeplink, { errorCorrectionLevel: 'Q', scale: 8 })
      .then(url => setImageUrl(url))
      .catch(err => {
        console.error(err)
        setImageUrl('')
      })
  }, [deeplink])

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: 2,
        padding: 2,
        overflow: 'hidden',
      }}>
      <Paper sx={{ flex: 1 }}>
        <Stack spacing={4} sx={{ padding: 2 }}>
          <Typography component="strong" variant="h6">
            Générer un QR code de connexion
          </Typography>
          <Typography>Saisissez ci-dessous les informations de connexion de l’utilisateur.</Typography>
          <TextField
            label="Nom d’utilisateur"
            type="username"
            id="username"
            name="username"
            autoComplete="off"
            variant="standard"
            required
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <TextField
            label="Mot de passe"
            type="text"
            id="password"
            name="password"
            autoComplete="off"
            variant="standard"
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Divider />
          <Typography>
            Le lien profond suivant (<em>deeplink</em>) est un lien qui permet à l’utilisateur de se connecter
            automatiquement dans l’application sans saisir manuellement ses identifiants. Ce lien est contenu dans le QR
            code ci-contre.
          </Typography>
          <TextField label="Lien profond" type="text" variant="standard" disabled value={deeplink} />
        </Stack>
      </Paper>
      <Paper sx={{ flex: 1 }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            padding: 2,
          }}>
          <Typography>
            {imageUrl ? (
              <>
                Voici le QR code généré pour <strong>{username}</strong>&nbsp;:
              </>
            ) : (
              <>Le QR code apparaitra ci-dessous&nbsp;:</>
            )}
          </Typography>
          {imageUrl ? (
            <img src={imageUrl} style={{ width: 256 }} />
          ) : (
            <Skeleton variant="rectangular" width={256} height={256} />
          )}
          <Button
            component="a"
            variant="contained"
            size="large"
            disabled={!imageUrl}
            href={imageUrl}
            download={`QR Code de ${username}.png`}>
            Télécharger l’image
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}
